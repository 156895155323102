<template>
    <div class="page bg-white">

        <van-field v-model="title" label="活动主题" placeholder=""/>
        <van-field v-model="code" label="电子票号" placeholder="请输入电子票号或者手机号码或标签"/>
        <div class="footer">
            <van-button type="info" class="mr10" block @click="commit">提交</van-button>
        </div>
    </div>
</template>
<script>
    import {Row, Col, Panel, Button, Cell, Field} from "vant";
    import {ver_ticker} from '@/request/api';

    export default {
        components: {
            [Row.name]: Row,
            [Col.name]: Col,
            [Panel.name]: Panel,
            [Button.name]: Button,
            [Cell.name]: Cell,
            [Field.name]: Field
        },
        data() {
            return {
                title: '',
                code: '',
                activity_id: 0
            };
        },
        mounted: function () {
            this.title = this.$route.query.title;
            this.activity_id = this.$route.query.id;
        },
        methods: {
            commit() {
                if (this.activity_id <= 0) {
                    this.$dialog({message: "活动不存在"});return;
                }
                if (this.code==''){
                    this.$dialog({message: "请输入票号"});return;
                }
                //提交验票
                ver_ticker({keyword:this.code,aid:this.activity_id}).then(res=>{
                    if(res.code==1){
                        this.$toast.fail("验票成功");
                    }else{
                        this.$toast.fail(res.msg);
                    }
                }).catch(error=>{
                    this.$toast.fail("验票失败");
                });
            }
        }
    };
</script>
<style lang="less">
    .page {
        height: 100vh;
    }

    .footer {
        padding: 0.5rem;
    }
</style>
